body {
  background-color: #F6F8FA;
}


.secondary-menu-item:hover {
  background-color: rgba(0, 230, 118, 0.12)!important;
}

.secondary-menu-item.Mui-selected {
  background-color: rgba(0, 230, 118, 0.08)!important;
}

.secondary-menu-item.Mui-selected:hover {
  background-color: rgb(185,246,202)!important;
}